import React from "react"
import styles from '../index.module.css'

import MainLayout from "../../layouts/MainLayout"
import SEO from "../../components/seo"

import logo from '../../images/logo.png'

const OldLoginPage = () => {
  return (
    <div>
      <div className={styles.loginBox}>
        <img className={styles.logo} src={logo} />
        <br/>
        <br/>
        <div>
          <a className="btn btn-primary" href="https://app.crowdlever.io/login">Login to your account</a>
        </div>
      </div>
    </div>
  )
}

export default OldLoginPage